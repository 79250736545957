import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CommonService } from "app/core/service/common/common.service";
import { exhaustMap, map, withLatestFrom } from "rxjs/operators";

import { dummyAction, fetchActiveCreator, fetchCategoriesLists, fetchChannel, fetchClips, fetchContenetOwners, fetchContract, fetchCreator, fetchEnergyLists, fetchMonitoringChannel, fetchSpeker, getActiveCreator, getCategoriesLists, getChannel, getClips, getContentOwners, getContract, getCreator, getEnergyLists, getMonitoringChannel, getSpeaker } from "./general.actions";
import { Store } from "@ngrx/store";
import { AppState } from "app/app.state";
import { getAllChannel, getAllClips, getAllContract, getAllEnergyLists, getAllMonitoringChannel, getAllSpeaker, getallActiveCreator, getallContentOwner, getallCreator, getallcategoryList } from "./general.selectors";
import { of } from "rxjs";
import { MonitoringChannelsService } from "../monitoring-channels/_services/monitoring-channels.service";


@Injectable()

export class GeneralEffects {
    constructor(private actions$: Actions, private commonservice: CommonService, private monCHService: MonitoringChannelsService, private store: Store<AppState>) {
    }
    contentOwners$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getContentOwners),
            withLatestFrom(this.store.select(getallContentOwner)),
            exhaustMap(([action, contentOwners]) => {
                if (contentOwners.length == 0) {
                    return this.commonservice.getall({ type: 4 }).pipe(
                        map((data: any) => {
                            return fetchContenetOwners({ contentOwners: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    creators$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCreator),
            withLatestFrom(this.store.select(getallCreator)),
            exhaustMap(([action, creators]) => {
                if (!creators.length) {
                    return this.commonservice.getall({ type: 3 }).pipe(
                        map((data: any) => {
                            return fetchCreator({ creators: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    activeCreators$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getActiveCreator),
            withLatestFrom(this.store.select(getallActiveCreator)),
            exhaustMap(([action, creators]) => {
                if (!creators.length) {
                    return this.commonservice.getall({ type: 3, inactive : true }).pipe(
                        map((data: any) => {
                            return fetchActiveCreator({ creators: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    clips$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getClips),
            withLatestFrom(this.store.select(getAllClips)),
            exhaustMap(([action, clips]) => {
                if (!clips.length) {
                    return this.commonservice.getclip().pipe(
                        map((data: any) => {
                            return fetchClips({ clips: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    speakers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getSpeaker),
            withLatestFrom(this.store.select(getAllSpeaker)),
            exhaustMap(([action, speakers]) => {
                if (!speakers.length) {
                    return this.commonservice.getallartist().pipe(
                        map((data: any) => {
                            return fetchSpeker({ speakers: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    contracts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getContract),
            withLatestFrom(this.store.select(getAllContract)),
            exhaustMap(([action, contract]) => {
                const params = {
                    search: "",
                };
                if (!contract.length) {
                    return this.commonservice.getallcontracts(params).pipe(
                        map((data: any) => {
                            return fetchContract({ contracts: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    channels$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChannel),
            withLatestFrom(this.store.select(getAllChannel)),
            exhaustMap(([action, channels]) => {
                const params = {
                    search: "",
                }
                if (!channels.length) {
                    return this.commonservice.getallchannel(params).pipe(
                        map((data: any) => {
                            return fetchChannel({ channels: data.result })
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    monitoringChannel$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getMonitoringChannel),
            withLatestFrom(this.store.select(getAllMonitoringChannel)),
            exhaustMap(([action, channels]) => {
                if (!channels.length) {
                    return this.monCHService.getchannel().pipe(
                        map((data: any) => {
                            return fetchMonitoringChannel({ channels: data.result })
                        })
                    )
                }
                return of(dummyAction());
            })
        )
    })

    energyLists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEnergyLists),
            withLatestFrom(this.store.select(getAllEnergyLists)),
            exhaustMap(([action, energy]) => {
                const params = {
                    search: "",
                }
                if (!energy.length) {
                    return this.commonservice.getallenergyleavel(params).pipe(
                        map((data: any) => {
                            return fetchEnergyLists({ energyLists: data.result });
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })

    categoryLists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCategoriesLists),
            withLatestFrom(this.store.select(getallcategoryList)),
            exhaustMap(([action,categories]) => {
                const params = {
                    search: "",
                    category: ""
                }
                if (!categories.length) {
                   
                    return this.commonservice.getallcategory(params).pipe(
                        map((data: any) => {
                            return fetchCategoriesLists({ categoriesLists: data.result });
                        })
                    )
                }
                return of(dummyAction())
            })
        )
    })
}