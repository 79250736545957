import { EnergyLevel } from "app/core/models/energy-level.model"
import { Clip } from "./models/Clip.model"
import { Creator } from "./models/Creator.model"
import { Channel } from "./models/channel.model"
import { ContentOwner } from "./models/contentOwner.model"
import { Contract } from "./models/contract.model"
import { monitoringChannel } from "./models/monitoringChannel.model"
import { Speaker } from "./models/speaker.model"
import { CategoryLists } from "./models/categoryList.model"

export interface generalState {
    contentOwners: ContentOwner[],
    creators : Creator[],
    activeCreators : Creator[],
    clips : Clip[],
    speakers : Speaker[],
    contracts : Contract[],
    channels : Channel[],
    monitoringChannels : monitoringChannel[],
    energyLists : EnergyLevel[],
    categoryLists : CategoryLists[]
}

export const generalIntialState : any = {
    contentOwners: [],
    creators : [],
    activeCreators : [],
    clips : [],
    speakers : [],
    contracts : [],
    channels : [],
    monitoringChannels : [],
    energyLists : [],
    categoryLists : []
}