import { Component, ViewContainerRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AuthService } from './core/service/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;


    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private authService: AuthService) {
    }


    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                window.scrollTo(0, 0)
                const rt = this.getChild(this.activatedRoute);
                rt.data.subscribe(data => {

                    if (this.authService.isRH() || this.authService.isRHTeam()) {
                        if(data.title == 'Channels'){
                            this.titleService.setTitle('Sublicensee Channels | DRM')
                        }else if(data.title == 'DRM YouTube Videos'){
                            this.titleService.setTitle('Sublicensee Videos | DRM')
                        } else if(data.title == 'By YouTube Videos'){
                            this.titleService.setTitle('By Sublicensee videos | DRM')
                        } else if(data.title == 'Payment History By Channel'){
                            this.titleService.setTitle('By Sublicense Channels | DRM')
                        } else if(data.title == 'Payment History By YouTube Videos'){
                            this.titleService.setTitle('Payment History By Sublicensee videos | DRM')
                        } else{
                            this.titleService.setTitle(data.title + ' | DRM')
                        }
                    }else{
                        this.titleService.setTitle(data.title + ' | DRM')
                    }
                });
            });

    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}