export const Roles = Object.freeze({
  ADMIN: 1,
  MANAGER: 2,
  CREATOR: 3,
  RIGHT_HOLDER: 4,
  MANAGER_LIMITED: 5,
  VIEWER: 6,
  VIEWER_LIMITED: 7,
  RIGHT_HOLDER_TEAM: 8,
  CREATOR_TEAM: 9
});
