import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterService } from 'app/core/service/FilterService.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoringChannelsService {

  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingDelete$ = new BehaviorSubject<boolean>(false);
  private _isLoadingtable$ = new BehaviorSubject<boolean>(false);

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isLoadingtable$() {
    return this._isLoadingtable$.asObservable();
  }

  get isLoadingDelete$() {
    return this._isLoadingDelete$.asObservable();
  }

  delete(id) {
    this._isLoadingDelete$.next(true);
    return this.http.put(`${this.apiUrl}/channel/change-status/${id}`, { status: 3 }).pipe(finalize(() => {
      this._isLoadingDelete$.next(false);
    }));

  }

  deletechanee(id) {
    this._isLoadingDelete$.next(true);
    return this.http.delete(`${this.apiUrl}/poc/channel/${id}`).pipe(finalize(() => {
      this._isLoadingDelete$.next(false);
    }));
  }

  hashTagList() {
    this._isLoadingDelete$.next(true);
    return this.http.post(`${this.apiUrl}/poc/channel/hashtag`, { search : ""}).pipe(finalize(() => {
      this._isLoadingDelete$.next(false);
    }));
  }

  getall(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/poc/channel`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getchannel() {
    return this.http.post(`${this.apiUrl}/poc/channel/all`, { search: "" });
  }



  changeType(id, params) {
    this._isLoadingtable$.next(true);
    return this.http.put(`${this.apiUrl}/poc/channel/type-change/${id}`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  deleteChannel(id) {
    this._isLoadingtable$.next(true);
    return this.http.delete(`${this.apiUrl}/poc/channel/${id}`).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getById(id) {
    this._isLoading$.next(true);
    return this.http.patch(`${this.apiUrl}/poc/channel/${id}`, {}).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  getByIdMonitoring(id) {
    this._isLoading$.next(true);
    return this.http.patch(`${this.apiUrl}/poc/channel/${id}`, {}).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }
  create(params) {
    this._isLoading$.next(true);
    return this.http.post(`${this.apiUrl}/poc/channel/add`, params).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  update(id, params) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/poc/channel/${id}`, params).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }
}
