import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { controllers } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { generate, Subscriber, Subscription } from 'rxjs';
import { RightHoldersService } from '../../_services/right-holders.service';

@Component({
  selector: 'app-right-holder-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss', '../../../../../assets/sass/libs/select.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {
  private _currency: any;
  private _payment_type: any;
  private _rights_holder: any;
  @Input('rights_holder') set rights_holder(v) {
    this._rights_holder = v;
    this.initFormFromStart();
  };
  @Input('payment_type') set payment_type(v) {
    this._payment_type = v?.toString();
    this.initFormFromStart();
  };
  @Input('currency') set currency(v) {
    this._currency = v;
    this.initFormFromStart();
  };



  // either : 1 = Wise to bank account, 2 = Email, 3 = other
  public paymentTypeForm: FormGroup;

  // enabled in all cases
  public currencyForm: FormGroup;

  // enabled in paymentType = 1
  public paymentRadioForm: FormGroup;
  public paymentSpecificForm: FormGroup;

  // enabled in paymentType = 2
  public emailForm: FormGroup;

  public isLoadingForm$;

  public formResponse;
  public usageInfo;
  public selectedForm;

  public submitted = false;

  public currencyChange: Subscription;
  public currencySubscription: Subscription;
  paymentRadioChanges: Subscription;
  paymentTypeChange: Subscription;

  constructor(private rightHolderService: RightHoldersService, private toaster: ToastrService) {
    this.isLoadingForm$ = this.rightHolderService.isLoadingForm$;
  }

  submit() {
    this.submitted = true;
    if (!this.paymentTypeForm.valid || !this.currencyForm.valid) return;

    const payment_type = this.paymentTypeForm.controls.paymentType.value;
    const currency = this.currencyForm.controls.currency.value;
    const commonDetails = {
      rights_holder: this._rights_holder,
      currency,
      payment_type: +payment_type,
    }

    let data;
    if (payment_type === '1') {
      if (!this.paymentSpecificForm.valid) return;
      const paymentSpecificValue = this.paymentSpecificForm.value;
      delete paymentSpecificValue['accountHolderName'];

      data = {
        currency,
        type: this.paymentRadioForm.controls.type.value,
        ownedByCustomer: true,
        accountHolderName: this.paymentSpecificForm.controls.accountHolderName.value,
        details: {
          ...this.paymentSpecificForm.value
        }
      }
    }
    else if (payment_type === '2') {
      if (!this.emailForm.valid) return;
      data = {
        currency,
        type: "email",
        accountHolderName: this.emailForm.controls.accountHolderName.value,
        details: {
          email: this.emailForm.controls.email.value,
        }
      }
    }
    else {
      data = {};
    }

    this.rightHolderService.addPayment({
      ...commonDetails,
      data: { ...data }
    }).subscribe((response: any) => {
      this.toaster.success(response.message, "Success", {
        positionClass: 'toast-bottom-right',
      })
    }, err => {
      this.toaster.error(err.error.error.message ? err.error.error.message : 'Something went wrong', "Error", {
        positionClass: 'toast-bottom-right',
      })
    })
  }

  ngOnInit(): void {
    // Basic form initializations
    this.initFormFromStart();
  }

  changeFormBasedOnCurrency = (value) => {
    if (this.currencyArr.includes(value)) {
      const body = {
        "target": value,
        "sourceAmount": "0"
      }
      this.rightHolderService.currencyForm(body).subscribe((response: any) => {
        this.formResponse = response.result;
        this.formResponse = this.formResponse.filter((form) => form.type != 'email');
        this.initRadioButtons();
      });
    } else {
      this.formResponse = undefined;
    }
  }

  private initFormFromStart() {
    let selectedPaymentType = "1";
    if (this._payment_type != "" && this._payment_type != null) {
      selectedPaymentType = this._payment_type;
    }
    this.paymentTypeForm = new FormGroup({
      paymentType: new FormControl(selectedPaymentType, Validators.required)
    });
    this.initEmailForm();
    this.initCurrencyForm(true);
    this.paymentRadioForm = new FormGroup({
      type: new FormControl("")
    });
    this.paymentSpecificForm = new FormGroup({});

    // On main radio-payment-type change, init forms based on type
    if (this.paymentTypeChange)
      this.paymentTypeChange.unsubscribe();
    this.paymentTypeChange = this.paymentTypeForm.controls.paymentType.valueChanges.subscribe((value) => {
      this.submitted = false;
      if (value === "1") {
        this.formResponse = null;
        this.initCurrencyForm(true);
      } else if (value === "2") {
        this.initCurrencyForm();
        this.initEmailForm();
      } else {
        this.initCurrencyForm();
      }
    });
  }

  initEmailForm() {
    this.emailForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      accountHolderName: new FormControl("", [Validators.required, this.isAccountHolderNameValid()])
    });
  }

  initCurrencyForm(isFormDynamic = false) {
    this.currencyForm = new FormGroup({
      currency: new FormControl(this._currency, this.isCurrencyInArr()),
    });
    if (this.currencyChange) this.currencyChange.unsubscribe();
    if (isFormDynamic) {
      this.currencyChange = this.currencyForm.controls.currency.valueChanges.subscribe(this.changeFormBasedOnCurrency)
    }
  }

  initRadioButtons() {
    this.submitted = false;
    this.paymentRadioForm = new FormGroup({
      type: new FormControl(this.formResponse[0].type)
    });

    // selecting default 0th type form
    this.usageInfo = this.formResponse[0].usageInfo;
    const fields = this.formResponse[0].fields.slice();
    this.initSpecificForm(fields);

    if (this.paymentRadioChanges) this.paymentRadioChanges.unsubscribe();
    this.paymentRadioChanges = this.paymentRadioForm.controls.type.valueChanges.subscribe((value) => {
      const formType = this.formResponse.find((data) => data.type == value);

      // selecting form type based on radio
      this.usageInfo = formType.usageInfo;
      const fields = formType.fields.slice();
      this.initSpecificForm(fields);
    });
  }

  initSpecificForm(fields) {
    this.submitted = false;
    this.selectedForm = fields;
    this.paymentSpecificForm = new FormGroup({});
    // for (let field of fields) {
    //   const validators = [];
    //   if (field.group[0].validationRegexp) validators.push(Validators.pattern(field.group[0].validationRegexp));
    //   if (field.group[0].type == 'email') validators.push(Validators.email);
    //   if (field.group[0].required) validators.push(Validators.required);
    //   this.paymentSpecificForm.addControl(field.group[0].key, new FormControl("", validators));
    // }

    for (let field of fields) {
      for(let group of field.group){
        const validators = [];
        if (group.validationRegexp) validators.push(Validators.pattern(group.validationRegexp));
        if (group.type == 'email') validators.push(Validators.email);
        if (group.required) validators.push(Validators.required);
        this.paymentSpecificForm.addControl(group.key, new FormControl("", validators));
      }
    }
    // adding accountHolderName statically
    this.paymentSpecificForm.addControl('accountHolderName', new FormControl("", [Validators.required, this.isAccountHolderNameValid()]))
  }

  ngOnDestroy(): void {
    this.currencyChange?.unsubscribe();
    this.paymentRadioChanges?.unsubscribe();
    this.paymentTypeChange?.unsubscribe();
  }

  isCurrencyInArr(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const has = this.currencyArr.includes(control.value);
      return has ? null : { currencyNotDetected: { errMsg: control.value == '' ? "Currency is required field" : "Currency not found" } };
    };
  }

  isAccountHolderNameValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == "") return;
      const has = control.value.split(' ').length > 1;
      return has ? null : { invalidAccName: { errMsg: "Please enter the recipients first and last name." } };
    };
  }

  currencyArr = [
    'AED',
    'ARS',
    'AUD',
    'BDT',
    'BGN',
    'BRL',
    'CAD',
    'CHF',
    'CLP',
    'CNY',
    'CZK',
    'DKK',
    'EGP',
    'EUR',
    'GBP',
    'GEL',
    'GHS',
    'HKD',
    'HRK',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'JPY',
    'KES',
    'KRW',
    'LKR',
    'MAD',
    'MXN',
    'MYR',
    'NGN',
    'NOK',
    'NPR',
    'NZD',
    'PHP',
    'PKR',
    'PLN',
    'RON',
    'RUB',
    'SEK',
    'SGD',
    'THB',
    'TRY',
    'UAH',
    'USD',
    'VND',
    'ZAR',
  ];

}
