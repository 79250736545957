import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Roles } from 'app/roles';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

// const creator_routes = [
//   'dashboard',
//   'contracts',
//   'contracts_view',
//   'contracts_list',
//   'channels',
//   'channel_list',
//   'channel_add',
//   'channel_view',
//   'channel_edit',
//   'drm-youtube-videos',
//   'library',
//   'browse',
//   'royalties-earnings',
//   'royalties-by-right-holders',
//   'royalties-by-videos',
//   'contact_us',
//   'payment-history-landing-page',
//   'payment-history-by-videos',
//   'payment-history-by-channel',
//   'payment-history-by-rights-holders'
// ]

// const right_holder = [
//   'dashboard',
//   'contracts',
//   'channels',
//   'channel_list',
//   'channel_view',
//   'contracts_view',
//   'drm-youtube-videos',
//   'library',
//   'royalties-earnings',
//   'royalties-by-videos',
//   'payment-history-landing-page',
//   'payment-history-by-videos',
//   'payment-history-by-channel',
// ]

@Injectable({
  providedIn: 'root'
})


export class PermissionGuard implements CanActivate {
  constructor(private authService: AuthService,
    private route: Router,
    private toaster: ToastrService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userType = this.authService.currentUserValue?.type;
    const hasAccess = route.data.permissions;

    let isAllowed = hasAccess === 'ALLOW_ALL';
    isAllowed = isAllowed || Array.isArray(hasAccess) && hasAccess.includes(userType);

    if (isAllowed) return true;

    this.toaster.error('You are not authorized to access this page.', "error", {
      positionClass: 'toast-bottom-right',
    })
    this.route.navigate(['/dashboard'])

    return false;
  }
}
