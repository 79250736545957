import { createFeatureSelector, createSelector } from "@ngrx/store";
import { generalState } from "./general.state";

const getGeneralSelector = createFeatureSelector<generalState>('general');

export const getallContentOwner = createSelector(getGeneralSelector,(state) => {
    return state.contentOwners
})

export const getallCreator = createSelector(getGeneralSelector, (state) => {
    return state.creators
})

export const getallActiveCreator = createSelector(getGeneralSelector, (state) => {
    return state.activeCreators
})

export const getAllClips = createSelector(getGeneralSelector,(state) => {
    return state.clips
})

export const getAllSpeaker = createSelector(getGeneralSelector,(state) => {
    return state.speakers
})

export const getAllContract  = createSelector(getGeneralSelector,(state) => {
    return state.contracts
})

export const getAllChannel = createSelector(getGeneralSelector,(state) => {
    return state.channels
})

export const getAllMonitoringChannel = createSelector(getGeneralSelector,(state) => {
    return state.monitoringChannels;
})

export const getAllEnergyLists = createSelector(getGeneralSelector,(state) => {
    return state.energyLists
})

export const getallcategoryList = createSelector(getGeneralSelector,(state) => {
    return state.categoryLists
})