import { Creator, createAction, props } from "@ngrx/store";
import { generalState } from "./general.state";
import { ContentOwner } from "./models/contentOwner.model";
import { Clip } from "./models/Clip.model";
import { Speaker } from "./models/speaker.model";
import { Contract } from "./models/contract.model";
import { Channel } from "./models/channel.model";
import { monitoringChannel } from "./models/monitoringChannel.model";
import { EnergyLevel } from "app/core/models/energy-level.model";
import { CategoryLists } from "./models/categoryList.model";

export const getContentOwners = createAction('getContentOwners');
export const fetchContenetOwners = createAction('fetchContenetOwners', props<{ contentOwners : ContentOwner[] }>());

export const getCreator = createAction('getCreator');
export const fetchCreator = createAction('fetchCreator', props<{ creators : Creator[] }>())
export const dummyAction = createAction('dummyAction');

export const getActiveCreator = createAction('getActiveCreator');
export const fetchActiveCreator = createAction('fetchActiveCreator', props<{ creators : Creator[] }>())

export const getClips = createAction('getClips');
export const fetchClips = createAction('fetchClips', props<{ clips : Clip[] }>())

export const getSpeaker = createAction('getSpeaker');
export const fetchSpeker = createAction('fetchSpeker', props<{ speakers : Speaker[] }>());

export const getContract = createAction('getContract');
export const fetchContract = createAction('fetchContract', props<{ contracts : Contract[]}>())

export const getChannel = createAction('getChannel');
export const fetchChannel = createAction('fetchChannel', props<{ channels : Channel[]}>());

export const getMonitoringChannel = createAction('getMonitoringChannel');
export const fetchMonitoringChannel = createAction('fetchMonitoringChannel', props<{ channels : monitoringChannel[]}>());

export const getEnergyLists = createAction('getEnergyLists');
export const fetchEnergyLists = createAction('fetchEnergyLists', props<{ energyLists : EnergyLevel[] }>());

export const getCategoriesLists = createAction('getCategoriesLists');
export const fetchCategoriesLists = createAction('fetchCategoriesLists', props<{ categoriesLists : CategoryLists[] }>());