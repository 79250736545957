import { createReducer, on } from "@ngrx/store";
import { generalIntialState } from "./general.state";
import { fetchActiveCreator, fetchCategoriesLists, fetchChannel, fetchClips, fetchContenetOwners, fetchContract, fetchCreator, fetchEnergyLists, fetchMonitoringChannel, fetchSpeker, getContentOwners, getCreator } from "./general.actions";

const _generalReducer = createReducer(generalIntialState,
    on(getContentOwners, (state) => {
        return {
            ...state
        }
    }),
    on(fetchContenetOwners, (state, action) => {
        return {
            ...state,
            contentOwners: action.contentOwners
        }
    }),
    on(fetchCreator, (state, action) => {
        return {
            ...state,
            creators : action.creators
        }
    }),
    on(fetchActiveCreator, (state, action) => {
        return {
            ...state,
            activeCreators : action.creators
        }
    }),
    
    on(getCreator, (state) => {
        return {
            ...state
        }
    }),
    on(fetchClips, (state,action) => {
        return {
            ...state,
            clips : action.clips
        }
    }),
    on(fetchSpeker, (state,action) => {
        return {
            ...state,
            speakers : action.speakers
            
        }
    }),
    on(fetchContract, (state,action) => {
        return {
            ...state,
            contracts : action.contracts
        }
    }),
    on(fetchChannel, (state,action) => {
        return {
            ...state,
            channels : action.channels
        }
    }),
    on(fetchMonitoringChannel,(state,action) => {
        return {
            ...state,
            monitoringChannels : action.channels
        }
    }),
    on(fetchEnergyLists, (state,action) => {
        return {
            ...state,
            energyLists : action.energyLists
            
        }
    }),
    on(fetchCategoriesLists, (state,action) => {
        return {
            ...state,
            categoryLists : action.categoriesLists
            
        }
    })
)

export function generalReducer(state, action) {
    return _generalReducer(state, action);
}