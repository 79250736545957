import { Routes, RouterModule } from "@angular/router";
import { PermissionGuard } from "app/core/service/guard/permission.guard";
import { Roles } from "app/roles";
const {
  ADMIN,
  MANAGER,
  CREATOR,
  RIGHT_HOLDER,
  MANAGER_LIMITED,
  VIEWER,
  VIEWER_LIMITED,
  RIGHT_HOLDER_TEAM,
  CREATOR_TEAM
} = Roles;

//Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    data: { permissions: "ALLOW_ALL" },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "dashboard-graphs",
    data: { permissions: [ADMIN] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/dashboard-graphs/dashboard-graphs.module").then(
        (m) => m.DashboardGraphsModule
      ),
  },
  {
    path: "admins",
    data: { permissions: [ADMIN] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "upload",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, RIGHT_HOLDER, RIGHT_HOLDER_TEAM] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/upload/upload.module").then((m) => m.UploadModule),
  },
  {
    path: "creators",
    loadChildren: () =>
      import("app/modules/creator/creator.module").then((m) => m.CreatorModule),
  },
  {
    path: "team-members",
    loadChildren: () =>
      import("app/modules/team-member/team-member.module").then((m) => m.TeamMemberModule),
  },

  {
    path: "contracts",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/contracts/contracts.module").then(
        (m) => m.ContractsModule
      ),
  },
  {
    path: "right-holders",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        RIGHT_HOLDER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/right-holders/right-holders.module").then(
        (m) => m.RightHoldersModule
      ),
  },
  {
    path: "channels",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        RIGHT_HOLDER_TEAM,
        CREATOR_TEAM
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/channels/channels.module").then(
        (m) => m.ChannelsModule
      ),
  },
  {
    path: "drm-youtube-videos",
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        RIGHT_HOLDER_TEAM,
        CREATOR_TEAM
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/drm-youtube-videos/drm-youtube-videos.module").then(
        (m) => m.DrmYoutubeVideosModule
      ),
  },
  {
    path: "sublicensee-creators",
    data: {
      permissions: [

        RIGHT_HOLDER,
        RIGHT_HOLDER_TEAM,
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/sublicensee-creator/sublicensee-creator.module").then(
        (m) => m.SublicenseeCreatorModule
      ),
  },

  {
    path: "monitoring-channels",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/monitoring-channels/monitoring-channels.module").then(
        (m) => m.MonitoringChannelsModule
      ),
  },
  {
    path: "assets",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, RIGHT_HOLDER, RIGHT_HOLDER_TEAM],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/assest/assest.module").then(
        (m) => m.AssestModule
      ),
  },
  {
    path: "monitoring-youtube-videos",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import(
        "app/modules/monitoring-youtube-vedios/monitoring-youtube-vedios.module"
      ).then((m) => m.MonitoringYoutubeVediosModule),
  },
  {
    path: "categories",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/category/category.module").then(
        (m) => m.CategoryModule
      ),
  },
  {
    path: "energy-level",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/energy-level/energy-level.module").then(
        (m) => m.EnergyLevelModule
      ),
  },
  {
    path: "artists",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/artist/artist.module").then((m) => m.ArtistModule),
  },
  {
    path: "content-library",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        RIGHT_HOLDER_TEAM,
        CREATOR_TEAM
      ],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/content-library/content-library.module").then(
        (m) => m.ContentLibraryModule
      ),
  },
  {
    path: "update-profile",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        RIGHT_HOLDER_TEAM,
        CREATOR_TEAM
      ],
    },
    loadChildren: () =>
      import("app/modules/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "royalties-earnings",
    data: { permissions: [ADMIN, RIGHT_HOLDER, CREATOR, MANAGER, VIEWER] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/royalties/royalties.module").then(
        (m) => m.RoyaltiesModule
      ),
  },
  {
    path: "royalties",
    canActivate: [PermissionGuard],
    data: { permissions: [ADMIN, RIGHT_HOLDER, CREATOR, MANAGER, VIEWER] },
    loadChildren: () =>
      import("app/modules/royalties/royalties.module").then(
        (m) => m.RoyaltiesModule
      ),
  },

  {
    path: "claim-detail",
    loadChildren: () =>
      import("app/modules/claim-detail-module/claim-detail-module.module").then(
        (m) => m.ClaimDetailModuleModule
      ),
  },
  {
    path: "transaction",
    loadChildren: () =>
      import("app/modules/transaction/transaction.module").then(
        (m) => m.TransactionModule
      ),
  },
  {
    path: "payment-history",
    loadChildren: () =>
      import("app/modules/payment-history/payment-history.module").then(
        (m) => m.PaymentHistoryModule
      ),
  },
  {
    path: "payment-setting",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/payment-setting/payment-setting.module").then(
        (m) => m.PaymentSettingModule
      ),
  },
  // {
  //   path: "pages",
  //   loadChildren: () =>
  //     import("app/pages/full-pages/full-pages.module").then(
  //       (m) => m.FullPagesModule
  //     ),
  // },
  {
    path: "contact-us",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        CREATOR,
      ],
    },
    loadChildren: () =>
      import("app/modules/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: "activity-log-reports",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        CREATOR,
      ],
    },
    loadChildren: () =>
      import("app/modules/activity-log-reports/activity-log-reports.module").then(
        (m) => m.ActivityLogReportsModule
      ),
  },
  {
    path: "inquiry-list",
    canActivate: [PermissionGuard],
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    loadChildren: () =>
      import("app/modules/inquiry-list/inquiry.module").then(
        (m) => m.InquiryModule
      ),
  },
  {
    path: "browse",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
        CREATOR_TEAM
      ],
    },
    loadChildren: () =>
      import("app/modules/browse/browse.module").then((m) => m.BrowseModule),
  },
  {
    path: "dashboard-channel",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-channel/dashboard-channel.module").then(
        (m) => m.DashboardChannelModule
      ),
  },
  {
    path: "dashboard-podcast",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-podcast/dashboard-podcast.module").then(
        (m) => m.DashboardPodcastModule
      ),
  },
  {
    path: "dashboard-social-media",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import(
        "app/modules/dashboard-social-media/dashboard-social-media.module"
      ).then((m) => m.DashboardSocialMediaModule),
  },
  {
    path: "dashboard-shop",
    canActivate: [PermissionGuard],
    data: {
      permissions: [
        ADMIN,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/dashboard-shop/dashboard-shop.module").then(
        (m) => m.DashboardShopModule
      ),
  },
];
