import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { generalReducer } from 'app/modules/general/general.reducer';
import { EffectsModule } from '@ngrx/effects';
import { GeneralEffects } from 'app/modules/general/general.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('general', generalReducer),
    EffectsModule.forFeature([GeneralEffects]),
  ]
})
export class AllReducerModule { }
