<!--Error page starts-->
<section id="error">
    <div class="container-fluid">
      <div class="row auth-height full-height-vh">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="row">
            <div class="col-12 text-center">
              <img src="assets/img/gallery/error.png" alt="" class="img-fluid error-img mt-2" height="300" width="400">
              <h1 class="mt-4">404 - Page Not Found!</h1>
              <div class="w-75 error-text mx-auto mt-4">
                <p>The page you are looking for might have beel removed, had it's name changed, or is temporarily unavailable.</p>
              </div>
              <a [routerLink]="['/']" class="btn btn-warning my-2">Back To Home</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Error page ends-->
  