import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "app/core/service/auth/auth.service";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";
import { finalize, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ContactUsService {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingTable$ = new BehaviorSubject<boolean>(false);
  private _unclaimedCount$ = new BehaviorSubject<number>(0);
  private _audioCount$ = new BehaviorSubject<number>(0);
  private _uploadCount$ = new BehaviorSubject<number>(0);
  private _overviewCount$ = new BehaviorSubject<number>(0);
  private _badgeCount$ = new BehaviorSubject<number>(0);
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, public authService: AuthService,) { }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isLoadingTable$() {
    return this._isLoadingTable$.asObservable();
  }

  get badgeCount$() {
    return this._badgeCount$.asObservable();
  }

  get unclaimedCount$() {
    return this._unclaimedCount$.asObservable();
  }

  get audioCount$() {
    return this._audioCount$.asObservable();
  }
  get uploadCount$() {
    return this._uploadCount$.asObservable();
  }



  get overviewCount$() {
    return this._overviewCount$.asObservable();
  }

  addContactUs(contactData: { subject: string; messge: string }) {
    this._isLoading$.next(true);
    return this.http.post(`${this.apiUrl}/contact-us/add`, contactData).pipe(
      finalize(() => {
        this._isLoading$.next(false);
      })
    );
  }

  getContactUs(params) {
    this._isLoadingTable$.next(true);
    return this.http.post(`${this.apiUrl}/contact-us`, params).pipe(
      finalize(() => {
        this._isLoadingTable$.next(false);
      })
    );
  }

  setStatus(id, body) {
    this._isLoading$.next(true);
    return this.http
      .put(`${this.apiUrl}/contact-us/change-status/${id}`, body)
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
          this.getBadgeCount();
        })
      );
  }

  getBadgeCount() {
    this._isLoading$.next(true);
    return this.http
      .get(`${this.apiUrl}/contact-us/badge/count`)
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
        }),
      ).subscribe((response: any) => {
        this._badgeCount$.next(response.result);
      });
  }

  getUploadCount() {
    this._isLoading$.next(true);
    return this.http
      .post(`${this.apiUrl}/CID/asset/draft/count`, {
        "search": "",
        "asset_label": this.authService.isRH() ? this.authService.currentUserValue?.asset_label?.labelName : ""
      })
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
        }),
      ).subscribe((response: any) => {
        this._uploadCount$.next(response.result);
      });
  }


  getaudioCount() {
    return this.http
      .get(`${this.apiUrl}/poc/audio/count`).subscribe((response: any) => {
        this._audioCount$.next(response.result);
      });
  }

  getUnclaimCount() {
    this._isLoading$.next(true);
    return this.http
      .get(`${this.apiUrl}/youtube/video/unclaimed-count`)
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
        }),
      ).subscribe((response: any) => {
        this._unclaimedCount$.next(response.result);
      });
  }
  getOverviewCount() {
    this._isLoading$.next(true);
    return this.http
      .get(`${this.apiUrl}/payment/history/rights/holder/badge/count`)
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
        }),
      ).subscribe((response: any) => {
        this._overviewCount$.next(response.result);
      });
  }
}
