<style type="text/css">
    .pace .pace-activity {
        top: 19px;
        right: 19px;
        display: none;
    }

    .page-loading {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        background: rgba(51, 51, 51, 1.00);
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }

    .loading-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 38px;
        height: 38px;
        margin-left: -19px;
        margin-top: -19px;
    }

    app-root:empty~.page-loading {
        opacity: 1;
        z-index: 1;
    }
</style>

<div class="page-loading">
    <img src="assets/img/oval.svg" class="loading-icon" />
</div>