<div class="row match-height">
    <div class="col-xl-12 col-lg-12">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h4 class="card-title m-0">Payments</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <form [formGroup]="paymentTypeForm">
                                <div class="col-12">
                                    <fieldset class="form-group">
                                        <label for="paymentType">Payment Type <span class="text-danger">*</span></label>
                                        <div id="paymentType">
                                            <ul class="list-unstyled mb-0">
                                                <li class="d-inline-block mr-2">
                                                    <div class="radio">
                                                        <input type="radio" formControlName="paymentType" value="1"
                                                            id="paymentType1" />
                                                        <label for="paymentType1">Wise Payment Direct to Bank Account
                                                            (recommended)</label>
                                                    </div>
                                                </li>
                                                <li class="d-inline-block mr-2">
                                                    <div class="radio">
                                                        <input type="radio" formControlName="paymentType" value="2"
                                                            id="paymentType2" />
                                                        <label for="paymentType2">Wise Payment Direct to Email (no
                                                            automatic deposit)</label>
                                                    </div>
                                                </li>
                                                <li class="d-inline-block mr-2">
                                                    <div class="radio">
                                                        <input type="radio" formControlName="paymentType" value="3"
                                                            id="paymentType3" />
                                                        <label for="paymentType3">Other</label>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </fieldset>


                                </div>
                            </form>

                            <form [formGroup]="currencyForm">
                                <div class="col-12">
                                    <fieldset class="form-group">
                                        <label for="currency">Currency <i class="fa fa-spinner fa-spin"
                                                *ngIf="isLoadingForm$ | async"></i></label>
                                        <div id="currency">
                                            <ng-select placeholder="Select Currency" formControlName="currency">
                                                <ng-option *ngFor="let currency of currencyArr" [value]="currency">
                                                    {{currency}}</ng-option>
                                            </ng-select>

                                        </div>
                                        <div class="text-danger"
                                            *ngIf="submitted && currencyForm.get('currency').hasError('currencyNotDetected')">
                                            <span
                                                class="errormessage">{{currencyForm.get('currency').errors.currencyNotDetected.errMsg}}</span>
                                        </div>
                                    </fieldset>


                                </div>
                            </form>

                            <form *ngIf="paymentTypeForm.get('paymentType').value === '1' && formResponse"
                                [formGroup]="paymentRadioForm">
                                <div class="col-12">
                                    <fieldset class="form-group">
                                        <label for="paymentTypeInnerRadio">Type</label>
                                        <ul class="list-unstyled mb-0">
                                            <li class="d-inline-block mr-2"
                                                *ngFor="let data of formResponse; let ind = index">
                                                <div class="radio">
                                                    <input type="radio" formControlName="type" [value]="data.type"
                                                        [id]="'radio-payment-type-' + ind" />
                                                    <label [for]="'radio-payment-type-' + ind">{{ data.title }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                        <p *ngIf="usageInfo">
                                            {{ usageInfo}}
                                        </p>
                                    </fieldset>


                                </div>
                            </form>

                            <form *ngIf="paymentTypeForm.get('paymentType').value === '1' && formResponse"
                                [formGroup]="paymentSpecificForm">
                                <!-- account holder name (static field) -->
                                <div class="col-12">
                                    <fieldset class="form-group">
                                        <label for="accountHolderNameInput">Account Holder Name <span
                                                class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" id="accountHolderNameInput"
                                                class="form-control form-control-sm" placeholder="Account Holder Name"
                                                formControlName="accountHolderName" />
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get('accountHolderName').errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                        <div class="help-block mt-1 text-danger"
                                            *ngIf="submitted && paymentSpecificForm.get('accountHolderName').hasError('invalidAccName')">
                                            <ng-container>{{
                                                paymentSpecificForm.get('accountHolderName').errors?.invalidAccName.errMsg}}
                                            </ng-container>
                                        </div>
                                    </fieldset>
                                </div>
                                <!-- <div class="col-12" *ngFor="let field of selectedForm">


                                    
                                    <fieldset class="form-group" *ngIf="field.group[0].type == 'radio'">
                                        <label>{{ field.group[0].name }} <span
                                                *ngIf="field.group[0].required" class="text-danger">*</span></label>
                                        <ul class="list-unstyled mb-0">
                                            <li class="d-inline-block mr-2"
                                                *ngFor="let rd of field.group[0].valuesAllowed; let ind = index">
                                                <div class="radio">
                                                    <input type="radio" [formControlName]="field.group[0].key"
                                                        [value]="rd.key" [id]="'rad' + field.group[0].key +  ind"
                                                        [required]="field.group[0].required" />
                                                    <label [for]="'rad' + field.group[0].key + ind">{{ rd.name
                                                        }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.pattern"
                                            class="help-block mt-1 text-danger">Invalid format <ng-container
                                                *ngIf="field.group[0].example">e.g, {{ field.group[0].example }}
                                            </ng-container>
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                    </fieldset>

                                    
                                    <fieldset class="form-group" *ngIf="field.group[0].type == 'select'">
                                        <label>{{ field.group[0].name }} <span
                                                *ngIf="field.group[0].required" class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <ng-select [placeholder]="field.group[0].name"
                                                [formControlName]="field.group[0].key">
                                                <ng-option *ngFor="let opt of field.group[0].valuesAllowed"
                                                    [value]="opt.key">{{opt.name}}</ng-option>

                                            </ng-select>
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.pattern"
                                            class="help-block mt-1 text-danger">Invalid format <ng-container
                                                *ngIf="field.group[0].example">e.g, {{ field.group[0].example }}
                                            </ng-container>
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                    </fieldset>

                                    
                                    <fieldset class="form-group" *ngIf="field.group[0].type == 'text'">
                                        <label>{{ field.group[0].name }} <span
                                                *ngIf="field.group[0].required" class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-sm"
                                                [placeholder]="field.group[0].name"
                                                [formControlName]="field.group[0].key" />
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.pattern"
                                            class="help-block mt-1 text-danger">Invalid format <ng-container
                                                *ngIf="field.group[0].example">e.g, {{ field.group[0].example }}
                                            </ng-container>
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                    </fieldset>

                                   
                                    <fieldset class="form-group" *ngIf="field.group[0].type == 'email'">
                                        <label>{{ field.group[0].name }} <span
                                                *ngIf="field.group[0].required" class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="email" class="form-control form-control-sm"
                                                [placeholder]="field.group[0].name"
                                                [formControlName]="field.group[0].key" />
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.pattern"
                                            class="help-block mt-1 text-danger">Invalid format <ng-container
                                                *ngIf="field.group[0].example">e.g, {{ field.group[0].example }}
                                            </ng-container>
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.email"
                                            class="help-block mt-1 text-danger">Please enter a valid email!
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                    </fieldset>

                                   
                                    <fieldset class="form-group" *ngIf="field.group[0].type == 'number'">
                                        <label>{{ field.group[0].name }} <span
                                                *ngIf="field.group[0].required" class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="number" class="form-control form-control-sm"
                                                [placeholder]="field.group[0].name"
                                                [formControlName]="field.group[0].key" />
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.pattern"
                                            class="help-block mt-1 text-danger">Invalid format <ng-container
                                                *ngIf="field.group[0].example">e.g, {{ field.group[0].example }}
                                            </ng-container>
                                        </div>
                                        <div *ngIf="submitted && paymentSpecificForm.get(field.group[0].key).errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                    </fieldset>
                                </div> -->
                                <div class="col-12" *ngFor="let field of selectedForm">
                                    <ng-container *ngFor="let group of field.group">


                                        <!-- Radio -->
                                        <fieldset class="form-group" *ngIf="group.type == 'radio'">
                                            <label>{{ group.name }} <span *ngIf="group.required"
                                                    class="text-danger">*</span></label>
                                            <ul class="list-unstyled mb-0">
                                                <li class="d-inline-block mr-2"
                                                    *ngFor="let rd of group.valuesAllowed; let ind = index">
                                                    <div class="radio">
                                                        <input type="radio" [formControlName]="group.key"
                                                            [value]="rd.key" [id]="'rad' + group.key +  ind"
                                                            [required]="group.required" />
                                                        <label [for]="'rad' + group.key + ind">{{ rd.name
                                                            }}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.pattern"
                                                class="help-block mt-1 text-danger">Invalid format <ng-container
                                                    *ngIf="group.example">e.g, {{ group.example }}
                                                </ng-container>
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.required"
                                                class="help-block mt-1 text-danger">This field is required!
                                            </div>
                                        </fieldset>

                                        <!-- select -->
                                        <fieldset class="form-group" *ngIf="group.type == 'select'">
                                            <label>{{ group.name }} <span *ngIf="group.required"
                                                    class="text-danger">*</span></label>
                                            <div class="form-group">
                                                <ng-select [placeholder]="group.name"
                                                    [formControlName]="group.key">
                                                    <ng-option *ngFor="let opt of group.valuesAllowed"
                                                        [value]="opt.key">{{opt.name}}</ng-option>

                                                </ng-select>
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.pattern"
                                                class="help-block mt-1 text-danger">Invalid format <ng-container
                                                    *ngIf="group.example">e.g, {{ group.example }}
                                                </ng-container>
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.required"
                                                class="help-block mt-1 text-danger">This field is required!
                                            </div>
                                        </fieldset>

                                        <!-- text -->
                                        <fieldset class="form-group" *ngIf="group.type == 'text'">
                                            <label>{{ group.name }} <span *ngIf="group.required"
                                                    class="text-danger">*</span></label>
                                            <div class="form-group">
                                                <input type="text" class="form-control form-control-sm"
                                                    [placeholder]="group.name"
                                                    [formControlName]="group.key" />
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.pattern"
                                                class="help-block mt-1 text-danger">Invalid format <ng-container
                                                    *ngIf="group.example">e.g, {{ group.example }}
                                                </ng-container>
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.required"
                                                class="help-block mt-1 text-danger">This field is required!
                                            </div>
                                        </fieldset>

                                        <!-- email -->
                                        <fieldset class="form-group" *ngIf="group.type == 'email'">
                                            <label>{{ group.name }} <span *ngIf="group.required"
                                                    class="text-danger">*</span></label>
                                            <div class="form-group">
                                                <input type="email" class="form-control form-control-sm"
                                                    [placeholder]="group.name"
                                                    [formControlName]="group.key" />
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.pattern"
                                                class="help-block mt-1 text-danger">Invalid format <ng-container
                                                    *ngIf="group.example">e.g, {{ group.example }}
                                                </ng-container>
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.email"
                                                class="help-block mt-1 text-danger">Please enter a valid email!
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.required"
                                                class="help-block mt-1 text-danger">This field is required!
                                            </div>
                                        </fieldset>

                                        <!-- number -->
                                        <fieldset class="form-group" *ngIf="group.type == 'number'">
                                            <label>{{ group.name }} <span *ngIf="group.required"
                                                    class="text-danger">*</span></label>
                                            <div class="form-group">
                                                <input type="number" class="form-control form-control-sm"
                                                    [placeholder]="group.name"
                                                    [formControlName]="group.key" />
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.pattern"
                                                class="help-block mt-1 text-danger">Invalid format <ng-container
                                                    *ngIf="group.example">e.g, {{ group.example }}
                                                </ng-container>
                                            </div>
                                            <div *ngIf="submitted && paymentSpecificForm.get(group.key).errors?.required"
                                                class="help-block mt-1 text-danger">This field is required!
                                            </div>
                                        </fieldset>

                                    </ng-container>
                                </div>
                            </form>

                            <form *ngIf="paymentTypeForm.get('paymentType').value === '2'" [formGroup]="emailForm">
                                <div class="col-12">
                                    <!-- text -->
                                    <fieldset class="form-group">
                                        <label>Account Holder Name <span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-sm"
                                                placeholder="Account Holder Name" formControlName="accountHolderName" />
                                        </div>
                                        <div *ngIf="submitted && emailForm.get('accountHolderName').errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                        <div class="help-block mt-1 text-danger"
                                            *ngIf="submitted && emailForm.get('accountHolderName').hasError('invalidAccName')">
                                            <ng-container>{{
                                                emailForm.get('accountHolderName').errors?.invalidAccName.errMsg}}
                                            </ng-container>
                                        </div>
                                    </fieldset>

                                    <!-- email -->
                                    <fieldset class="form-group">
                                        <label>Email <span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="email" class="form-control form-control-sm" placeholder="Email"
                                                formControlName="email" />
                                        </div>
                                        <div *ngIf="submitted && emailForm.get('email').errors?.email"
                                            class="help-block mt-1 text-danger">Please enter a valid email!
                                        </div>
                                        <div *ngIf="submitted && emailForm.get('email').errors?.required"
                                            class="help-block mt-1 text-danger">This field is required!
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-sm btn-primary mr-2" (click)="submit()">Update Setting</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>